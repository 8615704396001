/* eslint-disable max-len */
import React from 'react';

const Disclaimer = () => (
  <section className="about__section">
    <h3 className="about__subtitle">Disclaimer</h3>
    <p>
      Zoning Atlas data developed by the Metropolitan Area Planning Council (&quot;MAPC&quot;) is expressly provided &quot;AS IS.&quot; MAPC MAKES NO WARRANTY OF ANY KIND, EXPRESS, IMPLIED, IN FACT OR ARISING BY OPERATION OF LAW, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT AND DATA ACCURACY. MAPC NEITHER REPRESENTS NOR WARRANTS THAT THE OPERATION OF THE ZONING ATLAS WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT ANY DEFECTS WILL BE CORRECTED. MAPC DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OF ZONING ATLAS OR THE RESULTS THEREOF, INCLUDING BUT NOT LIMITED TO THE CORRECTNESS, ACCURACY, RELIABILITY, OR USEFULNESS OF THE SOFTWARE.
    </p>
  </section>
);

export default Disclaimer;
